import { signOut } from 'next-auth/react';
import Image from 'next/image';
import NextLink from 'next/link';
import Router, { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { AiFillSetting } from 'react-icons/ai';
import { IoMdAddCircleOutline, IoMdExit } from 'react-icons/io';
import styled from 'styled-components';
import { useAuth } from 'contexts/AuthContext';
import { useEvento } from 'contexts/EventContext';
import { ScrollPositionEffectProps, useScrollPosition } from 'hooks/useScrollPosition';
import { NavItems, SingleNavItem } from 'types';
import { media } from 'utils/media';
import Container from './Container';
import { HamburgerIcon } from './HamburgerIcon';
import { ModalInformation } from './ModalInformation';
import PasswordChangeModal from './PasswordchangeModal';
import Logo from '../public/person-circle.png';


type NavbarProps = { items?: NavItems };
type ScrollingDirections = 'up' | 'down' | 'none';
type NavbarContainerProps = { colorTheme: string; hidden: boolean; transparent: boolean; isLogado: boolean };

export default function Navbar({ items }: NavbarProps) {
  const router = useRouter();
  const { pathname } = useRouter();
  const [scrollingDirection, setScrollingDirection] = useState<ScrollingDirections>('none');
  const { user, signOut: internalSignOut } = useAuth();
  const [showDropMenu, setShowDropMenu] = useState<boolean>();
  const [isOpenDraweMenu, setIsOpenDrawerMenu] = useState<boolean>(false);
  const [, setSizePage] = useState(0);
  const pathUrl = router.asPath;
  const { evento } = useEvento();
  const [temaNavbar, setTemaNavbar] = useState('');
  const { isConfirmedEmail, resendEmail, isResendEmailSucess, setIsResendEmailSucess } = useAuth();
  const isProfileUserPage = pathUrl === '/profile/user';

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  let lastScrollY = useRef(0);
  const lastRoute = useRef('');
  const stepSize = useRef(50);
  const isLogado = user?.id && pathUrl !== '/blog' && pathUrl !== '/about' && pathUrl !== '/faq' && pathUrl !== '/contact' ? true : false;

  useEffect(() => {
    if (pathname === '/[slug]' || pathname === '/meu-evento/[slug]') {
      setTemaNavbar(evento?.background ?? '#8B8EE8');
    } else {
      setTemaNavbar('#8B8EE8');
    }
  }, [evento?.background, pathname, setTemaNavbar]);

  const handleResize = (e: any) => {
    setSizePage(window.innerWidth);
  };

  useEffect(() => {
    setSizePage(window.innerWidth);
    window.addEventListener('resize', handleResize);
  }, []);

  useScrollPosition(scrollPositionCallback, [router.asPath], undefined, undefined, 50);

  function scrollPositionCallback({ currPos }: ScrollPositionEffectProps) {
    const routerPath = router.asPath;
    const hasRouteChanged = routerPath !== lastRoute.current;

    if (hasRouteChanged) {
      lastRoute.current = routerPath;
      setScrollingDirection('none');
      return;
    }

    const currentScrollY = currPos.y;
    const isScrollingUp = currentScrollY > lastScrollY.current;
    const scrollDifference = Math.abs(lastScrollY.current - currentScrollY);
    const hasScrolledWholeStep = scrollDifference >= stepSize.current;
    const isInNonCollapsibleArea = lastScrollY.current > -50;

    if (isInNonCollapsibleArea) {
      setScrollingDirection('none');
      lastScrollY.current = currentScrollY;
      return;
    }

    if (!hasScrolledWholeStep) {
      lastScrollY.current = currentScrollY;
      return;
    }

    setScrollingDirection(isScrollingUp ? 'up' : 'down');
    lastScrollY.current = currentScrollY;
  }

  const isNavbarHidden = scrollingDirection === 'down';
  const isTransparent = scrollingDirection === 'none';

  return (
    <>
      <NavbarContainer hidden={isNavbarHidden} colorTheme={temaNavbar} transparent={isTransparent} isLogado={isLogado}>
        <Content>
          <ModalInformation
            modalIsOpen={isResendEmailSucess}
            closeModal={setIsResendEmailSucess}
            message="Um email de confirmação foi enviado com sucesso!"
            typeMessage="sucess"
            titleMessage="Email enviado com sucesso!"
          />
          <NextLink href={'/'} passHref>
            <LogoWrapper>
              <img src={isLogado ? '/logo.svg' : '/logo.svg'} alt="Logo" aria-label="Ir para a página inicial" />
            </LogoWrapper>
          </NextLink>
          {items ? (
            <NavItemList>
              {items.map((singleItem) => (
                <NavItem key={singleItem.href} {...singleItem} />
              ))}
            </NavItemList>
          ) : (
            <ContainerMenu style={{ marginLeft: '8rem' }}>
              <Image src={Logo} height={48} width={48} alt="Logo" onClick={() => setShowDropMenu(!showDropMenu)} />
              {showDropMenu && (
                <ContainerDropMenu>
                  <LineInfo onClick={() => setShowDropMenu(!showDropMenu)}>
                    <img src="/person-circle.png" alt="" />
                    <span>
                      <NextLink href="/profile/user" passHref>
                        {user.name}
                      </NextLink>
                    </span>
                  </LineInfo>
                  <LineInfo onClick={() => setShowDropMenu(!showDropMenu)}>
                    <IoMdAddCircleOutline size={27} fill="#8B8EE8" />
                    <span>
                      <NextLink href="/new-event" passHref>
                        Criar Evento
                      </NextLink>
                    </span>
                  </LineInfo>
                  <LineInfo onClick={() => setShowDropMenu(!showDropMenu)}>
                    <img src="/06.png" alt="" />
                    <span>
                      <NextLink href="/meus-eventos" passHref>
                        Meus Eventos
                      </NextLink>
                    </span>
                  </LineInfo>
                  {user?.id && pathUrl !== '/profile/user' && (
                    <>
                      <LineInfo onClick={() => setShowDropMenu(!showDropMenu)}>
                        <img src="/04.png" alt="" />
                        <span>
                          <NextLink href="/profile/guest-list" passHref>
                            Lista de Convidados
                          </NextLink>
                        </span>
                      </LineInfo>
                      {/*
                        <LineInfo onClick={() => setShowDropMenu(!showDropMenu)}>
                        <img src="/07.png" alt="" />
                        <span>
                          <NextLink
                            href={
                              router.asPath === '/profile/user'
                                ? '/profile/gifts-received?isProfileUser=true'
                                : `/profile/gifts-received/eventId=${evento.id}`
                            }
                            passHref
                          >
                            Presentes Recebidos
                          </NextLink>
                        </span>
                      </LineInfo>
                      */}
                    </>
                  )}
                  {user?.id && (
                    <>
                      <PasswordChangeModal isOpen={isModalOpen} onRequestClose={closeModal} />

                      <LineInfo onClick={openModal}>
                        <AiFillSetting size={27} fill="#8B8EE8" />
                        <span>
                          <NextLink passHref href={''}>
                            Alterar senha
                          </NextLink>
                        </span>
                      </LineInfo>

                      <LineInfo
                        onClick={() => {
                          signOut();
                          internalSignOut();
                          setShowDropMenu(!showDropMenu);
                        }}
                      >
                        <IoMdExit size={27} fill="#8B8EE8" />
                        <span style={{ color: '#0000EE' }}>Sair</span>
                      </LineInfo>
                    </>
                  )}
                </ContainerDropMenu>
              )}
            </ContainerMenu>
          )}
          {items && (
            <HamburgerMenuWrapper>
              <HamburgerIcon aria-label="Toggle menu" onClick={() => setIsOpenDrawerMenu(!isOpenDraweMenu)} />
              {isOpenDraweMenu && (
                <ContainerDropDownMenu isOpen={isOpenDraweMenu}>
                  <a href="/about">Quem somos</a>
                  <a href="/faq">Como funciona</a>
                  <a href="/contact">Contato</a>
                  <a href="/login">Entrar</a>
                </ContainerDropDownMenu>
              )}
            </HamburgerMenuWrapper>
          )}
        </Content>
      </NavbarContainer>
      {!isConfirmedEmail && isProfileUserPage && (
        <AlertConfirmedEmail>
          <h3>
            Seu e-mail ainda não foi confirmado. Caso necessário,{' '}
            <span className="resendEmail" onClick={resendEmail}>
              clique aqui
            </span>{' '}
            para receber um novo e-mail.
          </h3>
        </AlertConfirmedEmail>
      )}
    </>
  );
}

function NavItem({ href, title, outlined }: SingleNavItem) {
  return (
    <NavItemWrapper outlined={outlined}>
      <span>
        <span onClick={() => Router.push(href)}>{title}</span>
      </span>
    </NavItemWrapper>
  );
}

const NavItemList = styled.div`
  display: flex;
  list-style: none;
  ${media('<desktop')} {
    display: none;
  }
`;

const HamburgerMenuWrapper = styled.div`
  ${media('>=desktop')} {
    display: none;
  }
`;

const LogoWrapper = styled.a`
  display: flex;
  margin-right: auto;
  text-decoration: none;
  height: 100%;
`;

const NavItemWrapper = styled.li<Partial<SingleNavItem>>`
  border-radius: 0.5rem;
  line-height: 2;

  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 80%;

  :hover {
    opacity: 0.9;
    cursor: pointer;
  }

  span {
    display: flex;
    letter-spacing: 0.025em;
    text-decoration: none;
    padding: 0.75rem 1.5rem;
    font-weight: normal;
    color: #fff6e8;
  }
  &:not(:last-child) {
    margin-right: 2rem;
  }

  :last-child {
    background-color: #5cc9aa;
    color: #f5f5f5;

    span {
      color: #f5f5f5;
    }
  }
`;

const NavbarContainer = styled.div<NavbarContainerProps>`
  display: flex;
  position: sticky;
  top: 0;
  padding: 1.5rem 0;
  width: 100%;
  height: 7rem;
  z-index: 99;
  background: ${({ isLogado, colorTheme }) => (isLogado ? colorTheme : '#8B8EE8')};
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
  transition-property: transform, visibility, height, box-shadow, background-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;

  /* @media (max-width: 500px) {
    height: 10rem;  
  } */
`;

const AlertConfirmedEmail = styled.div`
  width: 100%;
  padding: 5px 30px;
  background-color: #e36387;

  h3 {
    color: #fff;
    font-size: 16px;
  }

  .resendEmail {
    border-bottom: 2px solid #fff;
    padding: 0px 4px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Content = styled(Container)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ContainerDropMenu = styled.div`
  width: 280px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  top: 35px;
  right: 132px;
  align-self: center;
  border-radius: 8px;
  text-decoration: none;
  z-index: 99999999;

  span {
    cursor: pointer;
    font-size: 16px;
    color: '#8B8EE8';
    margin-left: 10px;

    a {
      text-decoration: none;
      font-style: none;
      color: '#8B8EE8';
    }
  }
  @media (max-width: 478px) {
    /* display: none; */
    right: 0px;
    top: 60px;
    width: 92vw;
    margin: 0 auto;
  }
`;

const ContainerMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 100px;
  transition: all 0.5s ease-out;

  @keyframes showDropmenu {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  img {
    cursor: pointer;
  }

  @media (max-width: 478px) {
    width: 80px;
  }
`;

const LineInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #8b8ee8;
  padding: 7px 2px;
  cursor: pointer;

  @media (max-width: 478px) {
    span {
      text-decoration: none;
    }

    .createdEvent {
      span {
        color: #fff;
      }
    }
  }

  img {
    width: 25px;
    height: 25px;
  }

  span {
  }
`;

const ContainerDropDownMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 50px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  right: ${(props) => (props.isOpen ? '0' : '-250px')};
  width: ${(props) => (props.isOpen ? '100vw' : '0')};
  height: 100vh;
  background-color: #fff6e8;
  padding: 10px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  visibility: ${(p) => (!p.isOpen ? 'hidden' : 'visible')};
  transform: ${(p) => (p.isOpen ? `translateX(0vw) translateZ(0) scale(1)` : 'translateX(-100vw) translateZ(0) scale(1)')};
  transition-property: transform, visibility, height, box-shadow, background-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;

  a {
    margin: 30px 0px 5px 80px;
    text-align: left;
    text-decoration: none;
    font-size: 3rem;
    color: #8b8ee8;

    ::hover {
      color: #000;
      border-bottom: 1px solid #000;
    }
  }

  text-align: center;
  font-size: 4rem;
`;
