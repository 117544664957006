import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import styled from 'styled-components';
import Container from 'components/Container';
import { useAuth } from 'contexts/AuthContext';
import { useEvento } from 'contexts/EventContext';
import { media } from 'utils/media';

type SingleFooterListItem = { title: string; href: string };
type FooterListItems = SingleFooterListItem[];
type SingleFooterList = { title: string; items: FooterListItems };
type FooterItems = SingleFooterList[];

const footerItems: FooterItems = [
  {
    title: 'Como funciona',
    items: [{ title: 'Perguntas frequentes', href: '/faq' }],
  },
  {
    title: 'Contato',
    items: [{ title: 'Fale conosco', href: '/contact' }],
  },
  {
    title: 'Institucional',
    items: [
      { title: 'Sobre nós', href: '/about' },
      { title: 'Termos de uso', href: '/termos-e-condicoes' },
      { title: 'Política de privacidade', href: '/privacy-policy' }
    ],
  },
  {
    title: 'Redes',
    items: [
      { title: 'Instagram', href: 'https://www.instagram.com/meunascimento/' }, 
    ],
  },
];

export default function Footer() {
  const { user } = useAuth();
  const router = useRouter();
  const { evento, temaBackground, setTemaBackground } = useEvento();
  const { pathname } = useRouter();
  const pathUrl = router.asPath;

  const isLogado =
    user?.id && pathUrl !== '/' && pathUrl !== '/blog' && pathUrl !== '/about' && pathUrl !== '/faq' && pathUrl !== '/contact'
      ? true
      : false;

  useEffect(() => {
    if (pathname === '/[slug]' || pathname === '/meu-evento/[slug]') {
      setTemaBackground(evento?.background ?? '#4397c3');
    } else {
      setTemaBackground('#fff6e8');
    }
  }, [evento?.background, pathname, setTemaBackground]);

  let date = new Date();
  let year = date.getFullYear();

  return (
    <FooterWrapper colorTheme={temaBackground} isLogado={isLogado}>
      <Container>
        <ListContainer>
          <Logo>               
            <img src="/logoFooter.svg" alt="Selo Google" className="google"/>   
            <p className="web-copyright">© {year} - Todos os direitos reservados.</p>
          </Logo>                            

          {footerItems.map((singleItem) => (
            <FooterList key={singleItem.title} {...singleItem} />
          ))}
           <Pag>

          <PaymentStickers>
            <img src="/selos-pagamento/selo-google.svg" alt="Selo Google" className="google"/>          
            <img src="/selos-pagamento/selo-pagarme.svg" alt="Selo Pagarme" className="pagarme"/>
            <img src="/selos-pagamento/ssl.svg" alt="Selo SSL" className="ssl"/>            
          </PaymentStickers>
        </Pag>
        </ListContainer>

      </Container>
      <p className="mobile-copyright">© {year} - Todos os direitos reservados.</p>
    </FooterWrapper>
  );
}

function FooterList({ title, items }: SingleFooterList) {
  return (
    <ListWrapper>
      <ListHeader>{title}</ListHeader>
      {items.map((singleItem) => (
        <ListItem key={singleItem.href} {...singleItem} />        
      ))}
    </ListWrapper>
  );
}

function ListItem({ title, href }: SingleFooterListItem) {
  const { evento, temaBackground, setTemaBackground } = useEvento();
  const { pathname } = useRouter();

  useEffect(() => {
    if (pathname === '/[slug]' || pathname === '/meu-evento/[slug]') {
      setTemaBackground(evento?.background ?? '#4397c3');
    } else {
      setTemaBackground('#4397c3');
    }
  }, [evento?.background, pathname, setTemaBackground]);
  return (
    <ListItemWrapper colorTheme={temaBackground}>
      <NextLink href={href} passHref>
        <a href="https://github.com">{title}</a>
      </NextLink>
    </ListItemWrapper>
  );
}
interface IThemeProps {
  colorTheme: string;
  isLogado?: boolean;
}
const FooterWrapper = styled.div<IThemeProps>`
  padding-top: 10rem;
  padding-bottom: 4rem;
  background: ${({ isLogado, colorTheme }) => (isLogado ? colorTheme : '#FFF6E8;')};
  color: ${({ colorTheme }) => (colorTheme === '#fff6e8' ? '#4397c3' : '#fff')};
  box-shadow: 0px 0px 0px #d3d3d3, 0px -1px 6px #d3d3d3;  

  .mobile-copyright {
    display: none;
  }

  h3 {
    font-size: 12px;
    text-align: center;
  }

  @media (max-width: 478px) {
  h3 {
    margin-top: 2.5rem;
  }

  .mobile-copyright {
    display: inline-block;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    margin-left: 85px;;
    }
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Logo = styled.div`
display: block;
  p {
    margin-top: 3rem;
    font-family: 'Nunito';
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: rgba(67, 151, 195, 0.6);
  }
  img {
    width: 90%;
    height: 31%;
  }
  @media (max-width: 600px) {
    img {
      margin-top: -5rem;
      margin-bottom: 4rem;
      width: 100%;
      height: 100%;
    }
  }
  @media (max-width: 478px) {
    p {
      display: none;
    }
  }



  p {
    font-size: 14px !important;
    color: #4397c3;
  }

  @media (max-width: 820px) {
    .mobile-copyright {
      display: block;
    }
    .web-copyright {
      display: none;
    }
  }
`;

const Pag = styled.div`
  img {
    width: 100%;
    height: 85%;
  }
  @media (max-width: 600px) {
    img {
      margin-top: -5rem;
      margin-bottom: 4rem;
      width: 90%;
    }
  }
`;

const ListHeader = styled.p`
  font-weight: bold;
  font-size: 2.25rem;
  margin-bottom: 1rem;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  margin-right: 5rem;
  & > *:not(:first-child) {
    margin-top: 1rem;
  }
  ${media('<=tablet')} {
    flex: 40%;
    margin-right: 1.5rem;
  }
  ${media('<=phone')} {
    margin-left: 6rem;
    flex: 50% 50%;
    margin-right: 0rem;
  }
`;

const ListItemWrapper = styled.p<IThemeProps>`
  font-size: 1.6rem;
  a {
    text-decoration: none;
    color: ${({ colorTheme }) => (colorTheme === '#fff6e8' ? '#4397c3' : '#fff')};
  }
`;

const PaymentStickers = styled.div`
  img {  
    width: 90px; 
    object-fit: contain;
    padding-bottom: 1.5rem;
  }
  @media (max-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    margin-left: 1rem;
    width: 100%;
    & > *:not(:first-child) {
      margin-left: 2rem;
    }
  }
`;